import React from 'react';
import ReactDOM from 'react-dom';
import { Route, HashRouter } from 'react-router-dom';
import { UserContext, log } from './context.js';
import NavBar from './components/NavBar.js';
import Home from './components/Home.js';
import CreateAccount from './components/CreateAccount.js';
import Login from './components/Login.js';
import Deposit from './components/Deposit.js';
import Withdraw from './components/Withdraw.js';
import Statement from './components/Statement.js';
import AllData from './components/AllData.js';
import LoadExternals from './components/LoadExternals.js';

var counterBankAppRendering = 0;

// https://stackoverflow.com/questions/69832748/error-error-a-route-is-only-ever-to-be-used-as-the-child-of-routes-element
// Routes and Route: https://reacttraining.com/blog/react-router-v6-pre/#introducing-routes

function BankApp() {
  counterBankAppRendering++;
  log(`rendering BankApp: ${counterBankAppRendering}`)();
  // const [name, setName]           = React.useState('');
  // const [email, setEmail]         = React.useState('');
  // const [password, setPassword]   = React.useState('');
  // const [balance, setBalance]     = React.useState(0);
  // const [statement, setStatement] = React.useState([]);
  // const [loggedIn, setLoggedIn]   = React.useState(false);
  // const userSetters = {setLoggedIn, setStatus, setName, setEmail, setPassword, setBalance, setStatement};
  
  // empty or null?
  // const [user, setUser] = React.useState({name:'',
  //                                        email: '',
  //                                        password: '',
  //                                        balance: 0,
  //                                        statement: [],
  //                                        loggedIn: false,
  //                                        accessToken: '',
  //                                        refreshToken: ''});

  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);

  // https://usehooks.com/useMemo/#:~:text=React%20has%20a%20built-in%20hook%20called%20useMemo%20that,below%20we%20have%20an%20expensive%20function%20called%20
  // https://youtu.be/FSGQvH2kewE

  React.useEffect (
    () => {
      if (!user) {
        log('BankApp rendered in useEffect is NOT logged in')();
      } else {
        log(`BankApp rendered in useEffect is logged in with ${user.email} `, user)();
        log(user.balance)();
      }
    }, []
  );  

  const handleSetUserProp = (property, newValue) => {
    setUser(user => ({ ...user, [property]: newValue }));
  }  

  const handleSetNewUser = (newUser) => {
    setUser(newUser);
  }  

  const handlePushStatementEntry = (newStatementEntry) => {
    let allStatements = [...user.statement];
    allStatements.push(newStatementEntry);
    setUsers( allStatements );
  }   

  const handleSetAllUsers = (allNewUsers) => {
    setUsers(allNewUsers);
  }   

  const handlePushUserToUsers = (aUser) => {
    let allNewUsers = [ ...users ];
    allNewUsers.push(aUser);
    setUsers( allNewUsers );
  } 

  log('user at BankApp', user)();
  log('users at BankApp', users)();

  return (
      <HashRouter>
        {/* <UserContext.Provider value={{users:[], currentUser:null, loggedIn: false, externalIP: String}}> */}
        <UserContext.Provider value={{users:users, currentUser:user, handleSetUserProp, handleSetNewUser, handlePushStatementEntry, handleSetAllUsers, handlePushUserToUsers, externalIP: String}}>
        <LoadExternals/>
        <NavBar/>
          <div className="container" style={{padding: "20px"}}>
          {/* <Routes> */}
            <Route path="/" exact component={Home} />
            <Route path="/home/" exact component={Home} />
            {/* <Route path="/createAccountOld" component={CreateAccount} /> */}
            <Route path="/createAccount" component={CreateAccount} />
            <Route path="/login" component={Login} />
            <Route path="/deposit" component={Deposit} />
            <Route path="/withdraw" component={Withdraw} />
            <Route path="/statement" component={Statement} />
            {/* in a final version, even this "alldata" route may be hidden in production using ternary w/ DEV_BUILD */}
            {/* { DEV_BUILD ? (<Route path="/alldata" component={AllData} />):(<></>)} */}
            <Route path="/alldata" component={AllData} />
            {/* <Route path="/logout" component={Logout} /> */}
            {/* <Redirect from='/logout/' to="/" /> */}
          </div>
        </UserContext.Provider>      
      </HashRouter>
    );
  }
  

  // TDD was rising error on "renderization" with ERRO: "Target container is not a DOM element".
  // this article https://www.onooks.com/jest-failing-with-target-container-is-not-a-dom-element-cause-it-tests-my-index-tsx/
  // pointed out a solution that actually worked: adding a "|| document.createElement('div')"
  ReactDOM.render(
    <BankApp/>,
    document.getElementById('root') || document.createElement('div')
  );

export default BankApp;