/* eslint-disable no-unused-expressions */

// Some cool readings:
// https://blog.logrocket.com/build-crud-application-react-firebase-web-sdk-v9/#:~:text=The%20Firebase%20v.9%20SDK%20introduces%20a%20new%20API,to%20perform%20operations%20like%20CRUD%20in%20the%20database.?msclkid=26e40a87bc0111ecbdf4250c11706b5d
// https://betterprogramming.pub/working-with-the-new-firebase-web-v9-modular-sdk-aad1c641640e
// https://stackoverflow.com/questions/34735580/how-to-do-a-redirect-to-another-route-with-react-router
// https://www.kindacode.com/article/programmatically-navigate-using-react-router/

import React from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleAuthProvider } from 'firebase/auth';
import superagent from 'superagent';
import { UserContext, baseURL, log, outputMessageInvalidEmailOrPassword } from '../context.js';
import { containsEmail_and_Update } from '../globalfunctions.js';
import Card from './Card.js';
import { auth, signInWithEmailAndPassword, signInWithPopup } from '../firebase-at-client.js';
import { provider } from '../firebase-at-client.js';
import swal from 'sweetalert';
// import Google from './Google.js';
import './Login.css';
import './Google.css';

function Login(){

  const [status, setStatus] = React.useState('');

  // for now (until we centralize logic in the App/ContextProvider, these two continue as they are "local" states for the forms being filled:
  const [email, setEmail]       = React.useState('');
  const [password, setPassword] = React.useState('');

  // was using both
  // const [loggedIn, setLoggedIn]  = React.useState(false);
  // was using only setter
  // const [name, setName]         = React.useState('');
  // const [balance, setBalance] = React.useState(0);
  // const [statement, setStatement] = React.useState([]);

  const history = useHistory();

  const ctx = React.useContext(UserContext);  
  
  // for inspection purposes
  // NOTE: app and firebaseApp point to the same object!
  // console.log('app:');
  // console.log(app);  
  // console.log('firebaseApp:');
  // console.log(firebaseApp);  
  // NOTE: firebase.auth is not available in react as it is when imported at html <script src="https://www.gstatic.com/firebasejs/7.24.0/firebase-auth.js"></script> 
  // and that is because of the new way Firebase v.9 is built !!
  // so the catch was to "get Auth" with getAuth
  // console.log('auth');
  // console.log(getAuth);
  // I am using a "auth" property I create
  // firebaseApp.auth = getAuth();
  // console.log(firebaseApp.auth);

  React.useEffect( () => {
    if (ctx.currentUser) {
      log('rendering Login logged in with:', ctx.currentUser)();
      // ctx.loggedIn = true;
      // setLoggedIn(true);
    } else {
      log('rendering Login NOT logged in with:', ctx.currentUser)();
      // ctx.loggedIn = false;
    }
    
  }, [ctx.currentUser]); //, loggedIn]);
  
  function validate(field, label){
      let message = '';
      if (!field) {
          message = `Cannot enter a blank ${label.toUpperCase()}`;
          setStatus(`Error: ${message}`);
          // alert(message);
          swal('Failed', message, 'error');
          return false;
      };

      setStatus('');
      return true;
  };

  // MOVED TO CONTEXT.JS:
  // function outputMessageInvalidEmailOrPassword(optionalDetails) {
  //   if (!optionalDetails) optionalDetails = '';
  //   let message = 'Please enter a valid user identified by email and password or create a new account.';
    
  //   alert(`Invalid login: failed validation of email + password.\n${message}\n${optionalDetails}`);
    
  //   setStatus(message);
  // }
  
  // FAILED TO MOVE TO GOOGLE.JS:
  function googleAccountLogged(user) {
    var url = `${baseURL}/accounts/loginThird/`;
    log(`Sent third party login at url ${url} for:`)();
    log({email: user.email})();

    superagent.post(url)
      .send({
         name: user.displayName,
         email: user.email,
         phoneNumber: user.phoneNumber,
         photoURL: user.photoURL,
         password: '',
         emailVerified: user.emailVerified,
         localId: user.reloadUserInfo.localId,
         thirdParty: user.thirdParty,
         providerId: user.providerId,
         externalIP: ctx.externalIP,
         apiKey: user.auth.config.apiKey,
         accessToken: user.stsTokenManager.accessToken,
         expirationTime: user.stsTokenManager.expirationTime,
         refreshToken: user.stsTokenManager.refreshToken,
        })
      .end((err, res) => {
        // the answer is processed here
        if (err) {
          //global.logger.error(err);
          log(err);
          let error = 'Connection error.';
          // alert(`Invalid login: ${error}\n${err}`);
          swal('Failed', `Invalid login: ${error}\n${err}`, 'error');
          setStatus(error);
          return
        } else {
          log(res)();
          log(res.text)();

          // ATTENTION: when select uses "findOne" we receive a single document
          // ATTENTION: when select uses "find" we receive and array with the answers
          // const account = res.body.account[0];
          const account = res.body.account;
          // do some async action with result:
          log(`Received account:`)();
          log(account)();
          // test if account is the same meaning user already exists:
          if (!account) {
            let error = 'Please enter a valid user identified by email and password or create a new account.';
            // alert(`Invalid login: failed validation of email + password.\n${error}`);
            swal('Failed', error, 'error');
            // setStatus(error);
            outputMessageInvalidEmailOrPassword(error, setStatus, setEmail);
            return
          } else {
            // A SERIES OF TESTS AND MESSAGES:
            
            if ((account.status === 'closed')||(account.status === 'blocked')) {
              let error = `Account is ${account.status}`;
              // alert(`Create Account failed: please contact our support for help!\n${error}`);
              swal('Failed', `Create Account failed: please contact our support for help!\n${error}`, 'error');
              setStatus(error);
              return
            }

            // this consistency test was valid for "Our" creation of Account, by maybe useless now that third party is providing email
            // unless state had time to update
            // if (account.email !== email) {
            //   let error = `Please try again later.`;
            //   alert(`Create Account failed: communication error!\n${error}`);
            //   setStatus(error);
            //   return
            // }
            // check:
            log(account.email)();
            // log(email)(); // email at state still not refreshed!

            // success
            // email matches, so user exists!!
            // setName(account.name);
            // setBalance(account.balance);
            // setStatement(account.statement);
            // setPassword(password);
            ctx.handleSetNewUser({_id: account._id,
                                  name: account.name,
                                  email: account.email,
                                  password: account.password,
                                  balance: account.balance,
                                  statement: account.statement,
                                  status: account.status,
                                  accessToken: account.accessToken,
                                  refreshToken: account.refreshToken,
                                  loggedIn: true})
        
            // if valid existing user was retrieved and returned, then all we need is to make him/her the current user
            // ctx.currentUser = account;
            // ctx.currentUser.password = password;

            // update all users
            // should we still use all users ?
            // first we need to check if it is not on local memory
            let found = containsEmail_and_Update(ctx.users, email, account);
            if ( (!found) || (found===null) ) {
             
              // ctx.users.push({
              //   _id: account._id,
              //   name: account.name,
              //   email: account.email,
              //   password: account.password,
              //   balance: account.balance,
              //   statement: account.statement,
              //   status: account.status,
              //   accessToken: account.accessToken,
              //   refreshToken: account.refreshToken,
              //   loggedIn: true});

              ctx.handlePushUserToUsers(
                {
                  _id: account._id,
                  name: account.name,
                  email: account.email,
                  password: account.password,
                  balance: account.balance,
                  statement: account.statement,
                  status: account.status,
                  accessToken: account.accessToken,
                  refreshToken: account.refreshToken,
                  loggedIn: true}
                );   
            }

            // setLoggedIn(true);
            // ctx.loggedIn = true;
            // alert(`User ${account.name} Sucessfully Logged in`);
            swal('Success', `User ${account.name} Successfully Logged in`, 'success');
            log('Try to redirect to "statement" from "Login/googleAccountLogged"')();
            history.push("/statement");            
          }          
        }
      }) 
  }

  function getName(email, password) {
    // name should be retrieved from database
    // but for now we "form" from first word at email

    // until 21/02/2022 we only called this
    // return containsEmailMatchingPassword(ctx.users, email, password);

    // from 22/02/2022 we check up user at database
    var url = `${baseURL}/accounts/login/`;
    log(url)();
    // var body = `{"email":"${email}"}`;
    // const info = req.query.info;
    // const userid = req.query.id;
    // const key = 'fde7f8d0b3c9471cbf787ea0fb0ca043';
    log(`Sent login for:`)();
    log({email: email.toLowerCase()})();
    
    // https://www.npmjs.com/package/superagent
    superagent.post(url)
      .send({email: email.toLowerCase(), password: password, externalIP: ctx.externalIP}) //, userid, key})
      .end((err, res) => {
        // the answer is processed here
        if (err) {
          //global.logger.error(err);
          log(err);
          let error = 'Connection error.';
          // alert(`Invalid login: ${error}\n${err}`);
          swal('Failed', `Invalid login: ${error}\n${err}`, 'error');
          setStatus(error);
          return
        } else {
          log(res)();
          log(res.text)();

          // ATTENTION: when select uses "findOne" we receive a single document
          // ATTENTION: when select uses "find" we receive and array with the answers
          // const account = res.body.account[0];
          const account = res.body.account;
          // do some async action with result:
          log(`Received account:`)();
          log(account)();
          // test if account is the same meaning user already exists:
          if (!account) {
            let error = 'Please enter a valid user identified by email and password or create a new account.';
            // alert(`Invalid login: failed validation of email + password.\n${error}`);
            swal('Failed', `Invalid login: failed validation of email + password.\n${error}`, 'error');
            // setStatus(error);
            outputMessageInvalidEmailOrPassword(error, setStatus, setEmail);
            return
          } else {
            // A SERIES OF TESTS AND MESSAGES:
            
            if ((account.status === 'closed')||(account.status === 'blocked')) {
              let error = `Account is ${account.status}`;
              // alert(`Create Account failed: please contact our support for help!\n${error}`);
              swal('Failed', `Create Account failed: please contact our support for help!\n${error}`, 'error');
              setStatus(error);
              return
            }

            // a consistency test
            if (account.email !== email) {
              let error = `Please try again later.`;
              // alert(`Create Account failed: communication error!\n${error}`);
              swal('Failed', `Create Account failed: communication error!\n${error}`, 'error');
              setStatus(error);
              return
            }

            // success
            // email matches, so user exists!!
            // setName(account.name);
            // setBalance(account.balance);
            // setStatement(account.statement);
            setPassword(password);
        
            // if valid existing user was retrieved and returned, then all we need is to make him/her the current user
            // ctx.currentUser = account;
            // ctx.currentUser.password = password;
            ctx.handleSetNewUser({
              _id: account._id,
              name: account.name,
              email: account.email,
              password: account.password,
              balance: account.balance,
              statement: account.statement,
              status: account.status,
              accessToken: account.accessToken,
              refreshToken: account.refreshToken,
              loggedIn: true})

            // update all users
            // should we still use all users ?
            // first we need to check if it is not on local memory
            let found = containsEmail_and_Update(ctx.users, email, account);
            if (!found) {
              // ctx.users.push({
              //   _id: account._id,
              //   name: account.name,
              //   email: account.email,
              //   password: password,
              //   balance: account.balance,
              //   statement: account.statement,
              //   status: account.status,
              //   accessToken: account.accessToken,
              //   refreshToken: account.refreshToken,
              //   loggedIn: true});

              ctx.handlePushUserToUsers(
                  {
                    _id: account._id,
                    name: account.name,
                    email: account.email,
                    password: account.password,
                    balance: account.balance,
                    statement: account.statement,
                    status: account.status,
                    accessToken: account.accessToken,
                    refreshToken: account.refreshToken,
                    loggedIn: true}
                  );                
            }

            // setLoggedIn(true);
            // ctx.loggedIn = true;
            // alert(`User ${account.name} Sucessfully Logged in`);
            swal('Success', `User ${account.name} Sucessfully Logged in`, 'success');
            log('Try to redirect at getName')();
            history.push("/statement");            
          }          
        }
      }) 
  }

  function preValidation(){
    // initial structure validation of all input
    // if (!validate(name, 'name')) return;
    if (!validate(email, 'email')) {
      //alert('Invalid email format');
      return false;
    }

    if (!validate(password, 'password')){
      //alert('Could not validate user');
      return false;
    }

    return true
  };  

  function handleLogin(){
    // // validate all
    // // if (!validate(name, 'name')) return;
    // if (!validate(email, 'email')) {
    //   //alert('Invalid email format');
    //   return;
    // }

    // if (!validate(password, 'password')){
    //   //alert('Could not validate user');
    //   return;
    // }

    // // since 22/02/2022 getName will get user data from server:
    // getName(email, password);

    // since 14/04/2022 validationIsCentralized
    if (preValidation) {
      getName(email, password);
    }
  }; 
  // old version 8:
  // const auth  = firebaseApp.auth(); // firebase.auth();      
  // new version 9:
  // import { getAuth } from 'firebase/auth'
  // const auth  = getAuth();

  function handleLoginGoogleAccount() {
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // ...
      log(`token: ${token}`)();
      log(`result`)();
      log(result)();

      user = {...user, thirdParty: result.providerId}
      log(`user`)();
      log(user)();
      // setName(user.displayName);
      setEmail(user.email.toLowerCase());
      googleAccountLogged(user);

    }).catch((error) => {
      // Handle Errors here.
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      let message = 'user/email: ' + email + 'with AuthCredential type ' + credential +  ' resulted ' + error.code + ':' + error.message;
      log(message)();
      outputMessageInvalidEmailOrPassword(message, setStatus, setEmail); 
    });
  }

  function handleLoginGoogleEmail() {
    if (preValidation) {
      // now we need to retrive user data from Google Email Auth
      // const auth  = firebaseApp.auth(); // firebase.auth();      
      // if it were directly from input:
      // const promise = auth.signInWithEmailAndPassword(email.value, password.value);
      // from state "auth" from "getAuth" also fails at Firebase v.9:
      // const promise = auth.signInWithEmailAndPassword(email, password);
      
      // THIS IS THE NEW WAY. ATTENTION: use () as getAuth is a function to be executed!!
      // import { getAuth } from 'firebase/auth'
      // const auth  = getAuth();
      // I've gotten auth and saved into "firebaseApp.auth"
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          if (userCredential.user) {
            const user = userCredential.user;
            // ...
            log(user)();
            getName(email, password);
          } else {
            // for now, our getName also handles errors
            getName(email, password);
          }
        })
        .catch( (error) => {
          // log(error.message)();
          // log(error.code)();
          let message = error.code + ':' + error.message;
          log(message)();
          outputMessageInvalidEmailOrPassword('', setStatus, setEmail); //message);
      });
    }
  }

  function logOut(){
    // google firebase LOGOUT too ?
    // ...

    // let's try the logout call so that the server can imediately cancel current user jwt token
    if (ctx.currentUser.refreshToken) {
    var url = `${baseURL}/accounts/logout/`;
    superagent.post(url)
      .send({refreshToken: ctx.currentUser.refreshToken})
      .end((err, res) => {
        // the answer is processed here
        if (err) {
          //global.logger.error(err);
          log(err);
          
          // no change in status is really necessary as logout will not demand successful response
          // let error = 'Connection error.';
          // alert(`Invalid login: ${error}\n${err}`);
          // setStatus(error);
          
          return
        } else {
          log(res)();
          log(res.text)();
        }

      })     
    }
    
    ctx.currentUser = null;
    // setLoggedIn(false);
    // ctx.loggedIn = false;

    // setName('');
    // setEmail('');
    // setPassword('');
    // setBalance(0);
    // setStatement([]);

    // log(ctx.currentUser)();    
  };

  function CardStatus() {
    return(
      <div className="erroLogin">
      { (!status) ? (
        <div></div>
        ):(
        <div><br/>{status}</div>)
      }
      </div>
    )
  }  

  function FormLogin() {
    return(<>
      Email<br/>
      <input type="input" className="form-control" id="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.currentTarget.value.toLowerCase())}/><br/>
      Password<br/>
      <input type="password" className="form-control" id="password" placeholder="Enter password" value={password} onChange={e => setPassword(e.currentTarget.value)}/><br/>
      <button 
        type="submit"
        className="btn btn-light"
        onClick={handleLogin}
        disabled={ ((email.length===0)||(password.length===0)) }
        >Login
      </button>
      </>      
    )
  }

  return (
    <Card
      bgcolor="warning"
      header="Login"
      // status={status}
      
      // check this empty structure with ternary
      // body={show ? (<></>):(<></>)}
      // https://stackoverflow.com/questions/42573017/in-react-es6-why-does-the-input-field-lose-focus-after-typing-a-character:
      body={!ctx.loggedIn ? (  
              <>
               { FormLogin() }
              </>
            ):(
              <>
              <h5>Logged in</h5>
              <button 
                type="submit" 
                className="btn btn-light" 
                onClick={logOut}
                >Logout
              </button>
              </>
            )}

      alternative = {!ctx.loggedIn ? (  
        <div>
          {/* this would became "Google():" but failed */}
          {/* <Google setStatus={setStatus} setEmail={setEmail} /> */}
          <button 
            type="submit"
            className="btn btn-light googleAccountLogin"
            onClick={ handleLoginGoogleAccount }
            disabled={ false }
            >Login with Google Account
          </button>  
         {/* <CardStatus/> */}
        </div>
        
      ):(
        <>
        </>
      )}

      status={status}

    />
  ) 
};

export default Login;
