import React from 'react';
import { UserContext, DEV_BUILD, log, baseURL } from '../context.js';
import superagent from 'superagent';
import { useHistory } from 'react-router-dom';
import './NavBar.css';
import swal from 'sweetalert';

const NavBar = () => {
    const ctx = React.useContext(UserContext); 
    const history = useHistory();
    function logOut(){
      // confirm exit?
      // https://stackoverflow.com/questions/63651514/how-to-make-react-usecontext-work-inside-a-sweetalert-2-modal
      // https://sweetalert.js.org/guides/#advanced-examples
      // https://sweetalert.js.org/docs/
      swal({
        title: "Exit?",
        text: "Do really want to logout?",
        icon: "warning", // v.1 of Sweet Alert DOES NOT has "question" mark type of icon // check v.2 at https://sweetalert2.github.io/#icons
        buttons: ["Stay", "Exit"], // first is cancel and second is ok which assumes true,
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((willExit) => {
        if (willExit) {
          //swal("Poof! Your imaginary file has been deleted!", {icon: "success",});

          // google firebase LOGOUT too ?
          // ...
      
          // let's try the logout call so that the server can imediately cancel current user jwt token
          if (ctx.currentUser){
            if (ctx.currentUser.refreshToken) {
            var url = `${baseURL}/accounts/logout/`;
            superagent.post(url)
              .send({refreshToken: ctx.currentUser.refreshToken})
              .end((err, res) => {
                // the answer is processed here
                if (err) {
                  //global.logger.error(err);
                  log(err);
                  
                  // no change in status is really necessary as logout will not demand successful response
                  // let error = 'Connection error.';
                  // alert(`Invalid login: ${error}\n${err}`);
                  // setStatus(error);
                  
                  return
                } else {
                  log(res)();
                  log(res.text)();
                }
        
              })     
            }
          }
          
          // ctx.currentUser = null;

          // ctx.handleSetNewUser({name:'',
          //                       email: '',
          //                       password: '',
          //                       balance: 0,
          //                       statement: [],
          //                       loggedIn: false,
          //                       accessToken: '',
          //                       refreshToken: ''});

          ctx.handleSetNewUser(null);                      

          // ctx.loggedIn = false;
          // ctx.handleSetUser('loggedIn', false);

          // nem precisaria esse history, pois o link do nav já manda pro /, mas está sendo mais rápido usar history
          history.push("/");  

        } else {
          //swal("Your imaginary file is safe!");
        }
      });


    }; 

    React.useEffect( () => {
      if (ctx.currentUser) {
        log('rendering NavBar logged in with:', ctx.currentUser)();
      } else {
        log('rendering NavBar NOT logged in with:', ctx.currentUser)();
      }
    }, [ctx.currentUser]);    

    // if (ctx.currentUser) {
    //   // setLoggedIn(true);
    //   // ctx.setUser.loggedIn(true);
    //   // since now setUser handles object user, as we need to change state of one property, we use destructing
    //   // but this caused: "Warning: Cannot update a component (`BankApp`) while rendering a different component (`NavBar`)"
    //   // ctx.setUser( {...ctx.currentUser, loggedIn: true} );
    //   // but this caused: "Warning: Cannot update a component (`BankApp`) while rendering a different component (`NavBar`)"
    //   // ctx.handleSetUser(ctx.currentUser.loggedIn, true);
    //   ctx.handleSetUser('loggedIn', true);
    // }
    
    
    // https://bootstrap-menu.com/detail-fixed-onscroll.html [fixed-top]
    return(
      <header>
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark ">
      <div className="container-fluid">
        <a className="navbar-brand" href="#/" title="EasyMoneyBank Home">
        <img alt="Home" src="../images/bitcoin.jpg" width="120" height="60"/>
        </a>
        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            { !ctx.currentUser ? (<>
              <li className="nav-item">
                <a className="nav-link"  href="#/createAccount/" title="Create a new user">Create Account</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#/login/" title="Enter user">Login</a>
              </li></>):(<></>)
            }
            { ctx.currentUser ? (<>
              <li className="nav-item">
                <a className="nav-link" href="#/deposit/" title="Put some cash in">Deposit</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#/withdraw/" title="Take some cash out">Withdraw</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#/statement/" title="Check out your account statement">Statement</a>
              </li>
              </>):(<></>)
            }
            { DEV_BUILD ? (<li className="nav-item">
              <a id="nav-link-alldata" className="nav-link" href="#/alldata/" title="See all data for development purposes">All Data</a>
            </li>):(<></>)}
            { ctx.currentUser ? (<>
              <li className="nav-item">
              <a className="nav-link" onClick={logOut} title="End session">Logout</a> 
                {/* removed href="/" so that logOut can ask for user confirmation and eventually NOT EXIT */}
                {/* <a className="nav-link" onClick={logOut} href="/" title="End session">Logout</a> */}
              </li>
              </>):(<></>)
            }
          </ul>
        </div>
        { ctx.currentUser ? (<div className="navbar-username">
          Hello, {ctx.currentUser.name} !
        </div>):(<></>) }       
        {/* { true ? (<div className="navbar-username">
          Hello, User Name !
        </div>):(<></>) }          */}
      </div>
      </nav>
      </header>
    );
};
  
export default NavBar;