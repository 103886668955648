// https://firebase.google.com/docs/web/setup:
import { initializeApp } from "firebase/app";
import { getAuth,
  onAuthStateChanged, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider  } from 'firebase/auth';

// import { getApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
  };

// Initialize Firebase
// NOTE: app and firebaseApp point to the same object!
// export const app = getApp();
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth();
// optionals: https://firebase.google.com/docs/auth/web/google-signin
// auth.languageCode = 'it';
// auth().useDeviceLanguage()
export const provider = new GoogleAuthProvider();
// optionals: https://firebase.google.com/docs/auth/web/google-signin
// provider.setCustomParameters({
//   'login_hint': 'user@example.com'
// });
export { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup };

// MORE:
// https://firebase.google.com/docs/auth/web/google-signin
// https://dev.to/betiol/how-to-handle-authentication-on-node-js-using-firebase-5ajn
// https://www.freecodecamp.org/news/react-native-firebase-tutorial/
// https://rnfirebase.io/auth/usage?msclkid=02280390bb3211ecbfe2f2fb428fffca
// https://www.freakyjolly.com/reactjs-sign-in-form-using-firebase-google-authentication/
// https://www.freakyjolly.com/google-signin-login-button-in-react-js-example-using-react-google_login-package
// https://jsfiddle.net/93cdmshm/60/
// https://cloud.google.com/appengine/docs/standard/python/authenticating-users-firebase-appengine

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need
// import firebase from 'firebase';
// import firebase from 'firebase/app';
// import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// this will allow access to .env
// In React dotenv/ env is a inbuilt feature ( if you are using the CREATE-REACT-APP ) command for starting a new project.
// Create a .env file at the project level and create a sample env variables. The format for creating a variable is REACT_APP_{VARIABLE}
// require('dotenv').config();

// // Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID
//   };

// Initialize Firebase
// initializeApp(firebaseConfig);

// // Initialize Firebase
// export const firebaseApp = initializeApp(firebaseConfig);

// export const auth = firebase.auth();

// const provider = new firebase.auth.GoogleAuthProvider();
// const provider = new GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });
// export const signInWithGoogle = () => signInWithPopup(provider);

// export { auth, firebase };
// export { provider };

