import React from 'react';
import superagent from 'superagent';
import { UserContext, log } from '../context.js';

function LoadExternals(){

  const ctx = React.useContext(UserContext);

  React.useEffect(() => {
      getExternalIP();
    }, []);
    
  const getExternalIP = () => {
          // alternatives for free ip:
          // https://checkip.amazonaws.com/
          // https://api.ipify.org/
          // https://geolocation-db.com/json/  [check example at: https://medium.com/how-to-react/how-to-get-user-ip-address-in-react-js-73eb295720d0]
          superagent.get('https://api.ipify.org/')
          .send({})
          .end((err, res) => {
          // the answer is processed here
          if (err) {
              //global.logger.error(err);
              log(err);
              return
          } else {
              // this logs here were causing Test Unit to hang:
              // Cannot log after tests are done. Did you forget to wait for something async in your test?
              // log(res)();
              // log(res.text)();
              const externalIP = res.text;
              // do some async action with result:
              // test if account is the same meaning user already exists:
              if (!externalIP) {
              return
              } else {
                  ctx.externalIP = externalIP;
              }          
          }
          }) 
      }    

  //   getExternalIP();

  return (
    <></>
  )
};

export default LoadExternals;