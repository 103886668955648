import React from 'react';
import swal from 'sweetalert';

const baseURL = process.env.REACT_APP_BASE_URL;

const DEV_BUILD = (process.env.REACT_APP_CLIENT_BUILD_TYPE === 'development');

const log = function() {
    // https://stackoverflow.com/questions/9559725/extending-console-log-without-affecting-log-line
    // extended console.log code here:
    if (DEV_BUILD) {
    var args = Array.prototype.slice.call(arguments);		
    args.unshift(console);
    return Function.prototype.bind.apply(console.log, args);
    } else {
        // here we return an "empty function to cancel the console.log"
        return () => {}
    }
}

const oneStatement = {
    entry: 0, // absolute index incremented for each entry
    kind: 'C', // Credit ou Debit, default = C
    value: 0 // amount
};

// var emptyUser = {
const emptyUser = {
    name: '',
    email: '',
    password: '',
    balance: 0,
    statement: [],
    status: '',
    accessToken: '',
    refreshToken: ''
};

const UserContext = React.createContext(null);

const outputMessageInvalidEmailOrPassword = (optionalDetails, aSetStatus) => {
    if (!optionalDetails) optionalDetails = '';
    let message = 'Please enter a valid user identified by email and password or create a new account.';
    
    // alert(`Invalid login: failed validation of email + password.\n${message}\n${optionalDetails}`);
    swal('Failed', `Invalid login: failed validation of email + password.\n${message}\n${optionalDetails}`, 'error');

    
    aSetStatus(message);
  }

log('client built for:')();
log(process.env.REACT_APP_CLIENT_BUILD_TYPE)();
log(baseURL)();

// export { UserContext, Route, Link, HashRouter, emptyUser, oneStatement };
export { UserContext, emptyUser, oneStatement, baseURL, DEV_BUILD, log, outputMessageInvalidEmailOrPassword };
