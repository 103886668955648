import React from 'react';
import Card from './Card.js';
import { UserContext, baseURL, log } from '../context.js';
import './Atm.css';
// luxon: a powerful, modern, and friendly library for parsing, validating, manipulating, formatting and localising dates.
// const { DateTime } = require("luxon");
import { DateTime } from 'luxon';
import superagent from 'superagent';
import swal from 'sweetalert';
// this is a common content for
// deposits and withdrawals
function ATM ( {isDeposit} ){
    const operation = ["Deposit", "WithDrawal"];
    // const [loggedIn, setLoggedIn]  = React.useState(false);
    const [operationInProgress, setOperationInProgress] = React.useState(true);
    const [operationAmount, setOperationAmount] = React.useState('');
    const [balance, setBalance] = React.useState(0);
    
    const ctx = React.useContext(UserContext);  
  
    React.useEffect( () => {
      if (ctx.currentUser) {
        log('Operation of ', operation[Number(!isDeposit)], ' logged in with', ctx.currentUser)();
      } else {
        log('Operation of ', operation[Number(!isDeposit)], ' NOT logged in:', ctx.currentUser)();
      }
    }, [balance, operationAmount]
    );

    const copyBalanceToUsers = (anEmail, newBalance) => {
      for (let i = 0; i < ctx.users.length; i++) {
          if (ctx.users[i].email !== undefined) {
              if (ctx.users[i].email === anEmail) {
                // Email exists, update balance
                ctx.users[i].balance = newBalance;
                return true;
                }
              }
          };
          
      return false;
      };

    const sendStatementEntry = (statementEntry, balance) => {
      var url = `${baseURL}/accounts/statementEntry/`;
      log(ctx.currentUser.accessToken)();
      superagent.post(url)
      // IMPORTANT: "set" goes into headers. NOTE THAT all properties in Headers are lowercase, so 
      // that when I tried passing some upperCase, it would not reach the other side correctly
      // so I had to lowecase properties
      // .set({ 'accessToken': ctx.currentUser.accessToken, Accept: 'application/json' })  
      .set({ 'accesstoken': ctx.currentUser.accessToken, accept: 'application/json' })  
      .send({id: ctx.currentUser._id, statementEntry, balance, accessToken: ctx.currentUser.accessToken})
      .end((err, res) => {
        // the answer is processed here
        if (err) {
          //global.logger.error(err);
          log(err);
          let error = 'Connection error.';
          // alert(`Transaction error:\n${error}`);
          swal('Failed', `Transaction error:\n${error}`, 'error');
          // setStatus(error);
          return
        } else {
          log(res)();
          log(res.text)();
          // ATTENTION: when select uses "find" we receive and array with the answers
          // const account = res.body.account[0];
          // ATTENTION: when select uses "findOne" we receive a single document:
          const account = res.body.account;
          // do some async action with result:
          log(`Received account:`)();
          log(account)();
          // test if account exists:
          if (!account) {
            let error = 'Failed user validation.';
            // alert(`Transaction error:\n${error}`);
            swal('Failed', `Transaction error:\n${error}`, 'error');
            // setStatus(error);
            return
          } else {
            // MELHORAR ESTE TESTE:
            // if ( (!account.name)||(!account.email)||(!account.balance)||(!account.statement) ) {
            //   let error = 'Failed loading user data!';
            //   alert(`Transaction error: failed loading data.\n${error}`);
            //   setStatus(error);
            //   return
            // } 
          }          

          if (account.email === ctx.currentUser.email) {
            // email matches, so user exists!!

            // database success, let's register in local memory:
            ctx.currentUser.statement.push(statementEntry);
            ctx.currentUser.balance = balance;
            setBalance(balance);

            // these two introduced data inconsistency: although "sent" to parent, de ctx memory seems not to be affect
            // should I "update" both State and ctx? or just stick to CTX ?
            // ctx.handleSetUserProp('balance', balance);
            // ctx.handlePushStatementEntry(statementEntry);

            // when feeding a new statement to current user the corresponding user at users seems to match because they point to same memory
            // but for balance we need a specific update since balance of current user has its specific memory slot
            // in the future the "id" should be used instead of "email"
            copyBalanceToUsers(ctx.currentUser.email, balance);
            setOperationInProgress(false);
            // alert(`Your ${operation[Number(!isDeposit)].toLowerCase()} of $ ${operationAmount} was SUCCESSFULLY received!`);
            swal('Success', `Your ${operation[Number(!isDeposit)].toLowerCase()} of $ ${operationAmount} was SUCCESSFULLY received!`, 'success');
            // finally we reset the field
            setOperationAmount('');            

          } else {
              let error = 'Failed saving transaction. Operation rolled back!';
              // alert(`Transaction error:\n${error}`);
              swal('Failed', `Transaction error:\n${error}`, 'error');
              // setStatus(error);
              return           
          }
        }
      })      
    }
  
    const handleChange = event => {
      log(`handleChange ${event.target.value}`)();
      let valueContent = event.target.value;
  
      // user may be trying to input a negative number, which is invalid
      // but the portfolio requirement gives an extra point for alert blocking negative numbers!
      if (event.target.value==="-") {
        setOperationAmount('-');
        return;
      }
      
      // in JavaScript, the best way to check for NaN is by checking for 
      // self-equality using either of the built-in equality operators, == or ===.
      // Because NaN is not equal to itself, NaN != NaN will always return true.
      let newAmount = Number(event.target.value);
      if (newAmount != newAmount) {
        // we set back to the previous amount:
        event.target.value = operationAmount;
        // alert(`NOT A NUMBER Alert! Please ENTER a VALID NUMBER for the amount you wish to ${operation[Number(!isDeposit)]}!`);
        swal('Information', `NOT A NUMBER Alert! Please ENTER a VALID NUMBER for the amount you wish to ${operation[Number(!isDeposit)]}!`, 'info');
      } else {
        setOperationAmount(newAmount);
      }
    };
 
    function handleOperationAmount(){
      let newStatementEntry = {};
      let newTotal = 0;
      setOperationInProgress(true);
  
      log(operation[Number(!isDeposit)] + 'ing for', ctx.currentUser.name, ctx.currentUser.email)();
      log(`handleOperationAmount ${operationAmount}`)();
  
      if (operationAmount < 0) {
        // alert("Please, enter a positive value");
        swal('Information', 'Please, enter a positive value', 'info');
        setOperationInProgress(false);
        return
      }
  
      if (operationAmount === 0) {
        setOperationInProgress(false);
        // alert('Set an amount higher than ZERO !');
        swal('Information', 'Set an amount higher than ZERO !', 'info');
        return
      }
      
      if ( (!isDeposit) && (operationAmount > ctx.currentUser.balance) ) {
        setOperationInProgress(false);
        // alert('Cannot withdraw more money than you have in your account. Please check if a loan is available for you!');
        swal('Information', 'Cannot withdraw more money than you have in your account. Please check if a loan is available for you!', 'info');
        setOperationAmount('');
        return
      } else {
        
        // https://stackoverflow.com/questions/61604836/useeffect-and-the-context-api
        // ATTENTION: "spread" works, but at first I was limiting scope of variable to block-scope!! Solution was to give variable a function scope!
        // this works, but "updatedStatement" needs at least a function-scope:
        //updatedStatement = [...ctx.currentUser.statement, {entry: ctx.currentUser.statement.length + 1, kind: 'C', value: operationAmount} ];
        
        const now = DateTime.now();
        log(now)();
        let DateTimeNowBR = now.toLocaleString();
        log(DateTimeNowBR)();
        let DateTimeNowISO = now.toISO();
        log(DateTimeNowISO)();

        if (isDeposit) {
            newStatementEntry = {entry: ctx.currentUser.statement.length + 1, kind: 'C', value: operationAmount, date: DateTimeNowISO};
            newTotal = ctx.currentUser.balance + operationAmount;
        } else {
            newStatementEntry = {entry: ctx.currentUser.statement.length + 1, kind: 'D', value: operationAmount, date: DateTimeNowISO};
            newTotal = ctx.currentUser.balance - operationAmount;
        }

        log(newStatementEntry)();

        sendStatementEntry(newStatementEntry, newTotal);

      }    
      // successCallback
      // // ctx.users.push({name:'to be retrieved',email,password,balance: newTotal});
      // // ctx.currentUser.statement = updatedStatement;
      
      // // "push" approach also works (maybe is will be fast in future):
      // ctx.currentUser.statement.push(newStatementEntry);
      // ctx.currentUser.balance = newTotal;
      // setBalance(newTotal);

      // // when feeding a new statement to current user the corresponding user at users seems to match because they point to same memory
      // // but for balance we need a specific update since balance of current user has its specific memory slot
      // // in the future the "id" should be used instead of "email"

    }
    
    const handleKey = event => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        handleOperationAmount();
      }    
    }
  
    return (
      <Card
        bgcolor={isDeposit ? "success" : "danger"}
        header={operation[Number(!isDeposit)]}
        // status={status}
        
        body={ctx.currentUser ?  
                (<div className="operation-logged">
                Balance ${ctx.currentUser.balance}<br/>
                <br/>
                {operation[Number(!isDeposit)]} Amount<br/>
                <input type="input" className="form-control" id="operationAmount" placeholder="Enter Amount" value={operationAmount} onKeyUp={handleKey} onChange={handleChange}/><br/>
                <button
                 type="submit"
                 className="btn btn-light"
                 onClick={handleOperationAmount}
                 disabled={( (operationAmount.length===0)||(operationAmount==='-') )}
                 >{operation[Number(!isDeposit)]}</button>
                </div>)
                :
                (
                <div className="operation-unlogged">
                Please log in
                </div>  
                )
              }
      />
    ) 
};

export default ATM;
  