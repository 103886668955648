import React from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleAuthProvider } from 'firebase/auth';
import { auth, createUserWithEmailAndPassword, signInWithPopup } from '../firebase-at-client.js';
import { provider } from '../firebase-at-client.js';
import Card from './Card.js';
import { UserContext, baseURL, log, outputMessageInvalidEmailOrPassword } from '../context.js';
import { useFormik, useField, Form,FormikProvider } from 'formik';
// import { useFormikContext, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // requires npm install yup
import { getCharacterLength, nameValidateFormat, emailValidateFormat, containsName_and_Email, containsEmail_and_Update } from '../globalfunctions.js';
import superagent from 'superagent';
import './CreateAccount.css';
import './Google.css';
import swal from 'sweetalert';

const TextInputLiveFeedback = ({ label, helpText, setterValid, ...props }) => {
  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the has been visited (touched === true)
  
  const [field, meta] = useField(props);
  // const {setValue} = helpers;
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const showFeedback = (!!didFocus && field.value.trim().length > 2) || meta.touched;

  if (field.value === '') {
    setterValid(false);    
  } else {
    if (typeof meta.error !== 'undefined') {
          if (meta.error) {
            setterValid(false);
          } else {
            setterValid(true);
          }
        } else {
          setterValid(true);
    }
  };

  const TestValidInput = () => {

    // const { values, submitForm } = useFormikContext();

    // React.useEffect(() => {
    //   if (typeof meta.error !== 'undefined') {
    //     if (meta.error) {
    //       setterValid(false);
    //     } else {
    //       setterValid(true);
    //     }
    //   } else {
    //     setterValid(true);
    //   }

    // }); //, [meta, values]);
    
    return null;
  };   

  return (
    <div
      className={`formik-control ${
        showFeedback ? (meta.error ? 'invalid' : 'valid') : ''
      }`}
    >
      <div className="flex items-center space-between">
        <label htmlFor={props.id}>{label}</label>{' '}
        {showFeedback ? (
          <div
            id={`${props.id}-feedback`}
            aria-live="polite"
            className="feedback text-sm"
          >
            {meta.error ? meta.error : '✓'}
          </div>
        ) : null}
      </div>
      <input
        {...props}
        {...field}
        aria-describedby={`${props.id}-feedback ${props.id}-help`}
        onFocus={handleFocus}
        // value={value}
        // onChange={onChangeEvent}
        // onChange={props.handleChange}
        
      />
      <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
        {helpText}
      </div>
      <TestValidInput />
    </div>
  );
};

function CreateAccount(){
    // const [showButtonAdd, setShowButtonAdd] = React.useState(false);
    // const [loggedIn, setLoggedIn]  = React.useState(false);
    const [status, setStatus]     = React.useState('');
    const [name, setName]         = React.useState('');
    const [email, setEmail]       = React.useState('');
    const [password, setPassword] = React.useState('');
    // const [balance, setBalance] = React.useState(0);
    // const [statement, setStatement] = React.useState([]);
    const [validName, setValidName] = React.useState(false);
    const [validEmail, setValidEmail] = React.useState(false);
    const [validPassword, setValidPassword] = React.useState(false);
    
    const history = useHistory();
  
    const ctx = React.useContext(UserContext);  
  
    React.useEffect( () => {
      if (ctx.currentUser) {
        log('rendering Create Account logged in with', ctx.currentUser)();
        // ctx.loggedIn = true;
        // setLoggedIn(true);
      } else {
        log('Create account Not logged in:', ctx.currentUser)();
        // ctx.loggedIn = false;        
      }
    }, [ctx.currentUser]);

    React.useEffect(() => {
      log('Re-rendering thru valid change')()
    }, [validName, validEmail, validPassword]);

    // THIS ONE IS VERY SIMILAR TO THE ONE IN LOGIN
    // FAILED TO PLACE IN A COMMON AREAD
    // IN THE FUTURE WHEN ALL IS MOVED TO CONTEXT, MAYBE THIS COULD SHARE CODE WITH LOGIN.JS
    // FAILED TO MOVE TO GOOGLE.JS (but still not being used as ours login with email/password still brings more data consistancy)
    function handleLoginGoogleAccount() {
      signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
        log(`token: ${token}`)();
        log(`result`)();
        log(result)();

        user = {...user, thirdParty: result.providerId}
        log(`user`)();
        log(user)();
        // setName(user.displayName);
        setEmail(user.email);
        googleAccountLogged(user);

      }).catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        let message = 'user/email: ' + email + 'with AuthCredential type ' + credential +  ' resulted ' + error.code + ':' + error.message;
        log(message)();
        outputMessageInvalidEmailOrPassword(message, setStatus, setEmail); 
      });
    }        

    // THIS ONE IS VERY SIMILAR TO THE ONE IN LOGIN
    // FAILED TO PLACE IN A COMMON AREAD
    // IN THE FUTURE WHEN ALL IS MOVED TO CONTEXT, MAYBE THIS COULD SHARE CODE WITH LOGIN.JS
    // FAILED TO MOVE TO GOOGLE.JS:
    function googleAccountLogged(user) {
      var url = `${baseURL}/accounts/loginThird/`;
      log(`Sent third party login for:`)();
      log({email: user.email})();

      superagent.post(url)
        .send({
          name: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          password: '',
          emailVerified: user.emailVerified,
          localId: user.reloadUserInfo.localId,
          thirdParty: user.thirdParty,
          providerId: user.providerId,
          externalIP: ctx.externalIP,
          apiKey: user.auth.config.apiKey,
          accessToken: user.stsTokenManager.accessToken,
          expirationTime: user.stsTokenManager.expirationTime,
          refreshToken: user.stsTokenManager.refreshToken,
          })
        .end((err, res) => {
          // the answer is processed here
          if (err) {
            //global.logger.error(err);
            log(err);
            let error = 'Connection error.';
            // alert(`Invalid login: ${error}\n${err}`);
            swal('Failed', `Invalid login: ${error}\n${err}`, 'error');
            setStatus(error);
            return
          } else {
            log(res)();
            log(res.text)();

            // ATTENTION: when select uses "findOne" we receive a single document
            // ATTENTION: when select uses "find" we receive and array with the answers
            // const account = res.body.account[0];
            const account = res.body.account;
            // do some async action with result:
            log(`Received account:`)();
            log(account)();
            // test if account is the same meaning user already exists:
            if (!account) {
              let error = 'Please enter a valid user identified by email and password or create a new account.';
              // alert(`Invalid login: failed validation of email + password.\n${error}`);
              swal('Failed', `Invalid login: failed validation of email + password.\n${error}`, 'error');
              // setStatus(error);
              outputMessageInvalidEmailOrPassword(error, setStatus, setEmail);
              return
            } else {
              // A SERIES OF TESTS AND MESSAGES:
              
              if ((account.status === 'closed')||(account.status === 'blocked')) {
                let error = `Account is ${account.status}`;
                // alert(`Create Account failed: please contact our support for help!\n${error}`);
                swal('Failed', `Create Account failed: please contact our support for help!\n${error}`, 'error');
                setStatus(error);
                return
              }

              // this consistency test was valid for "Our" creation of Account, by maybe useless now that third party is providing email
              // unless state had time to update
              // if (account.email !== email) {
              //   let error = `Please try again later.`;
              //   alert(`Create Account failed: communication error!\n${error}`);
              //   setStatus(error);
              //   return
              // }
              // check:
              log(account.email)();
              // log(email)(); // email at state still not refreshed!

              // success
              // email matches, so user exists!!
              // setName(account.name);
              // setBalance(account.balance);
              // setStatement(account.statement);
              // setPassword(password);
              ctx.handleSetNewUser({_id: account._id,
                                    name: account.name,
                                    email: account.email,
                                    password: account.password,
                                    balance: account.balance,
                                    statement: account.statement,
                                    status: account.status,
                                    accessToken: account.accessToken,
                                    refreshToken: account.refreshToken,
                                    loggedIn: true})
          
              // if valid existing user was retrieved and returned, then all we need is to make him/her the current user
              // ctx.currentUser = account;
              // ctx.currentUser.password = password;

              // update all users
              // should we still use all users ?
              // first we need to check if it is not on local memory
              let found = containsEmail_and_Update(ctx.users, email, account);
              if ( (!found) || (found===null) ) {
              
                // ctx.users.push({
                //   _id: account._id,
                //   name: account.name,
                //   email: account.email,
                //   password: account.password,
                //   balance: account.balance,
                //   statement: account.statement,
                //   status: account.status,
                //   accessToken: account.accessToken,
                //   refreshToken: account.refreshToken,
                //   loggedIn: true});

                ctx.handlePushUserToUsers(
                  {
                    _id: account._id,
                    name: account.name,
                    email: account.email,
                    password: account.password,
                    balance: account.balance,
                    statement: account.statement,
                    status: account.status,
                    accessToken: account.accessToken,
                    refreshToken: account.refreshToken,
                    loggedIn: true}
                  );   
              }

              // setLoggedIn(true);
              // ctx.loggedIn = true;
              // alert(`User ${account.name} Successfully Logged in`);
              swal('Success', `User ${account.name} Successfully Logged in`, 'success');
              log('Try to redirect to "statement" from "Login/googleAccountLogged"')();
              history.push("/statement");            
            }          
          }
        }) 
    }    

    function currentUserLogoff  () {
      // ctx.loggedIn = false;

      ctx.currentUser.name = '';
      ctx.currentUser.email = '';
      ctx.currentUser.password = '';
      ctx.currentUser.balance = 0;
      ctx.currentUser.statement = [];
      // ctx.handle?
    }
  
    function currentUserNull () {
      // ctx.loggedIn = false;
      ctx.currentUser = null;
      // do it both way??
      ctx.handleSetNewUser(null);
    }  
  
    const passwordValidateFormat = password => {
      if (password.trim() === '') {
        return 'Field required'; //'Email is required';
      }
  
      if (getCharacterLength(password)>=6) {
      //if (password.length >=6 ) {
        return null;
      } else {
        return 'Please enter password with at least 6 alphacharacters';
      }
      
    };
  
    const validateFields = {
      email: emailValidation,
      password: passwordValidation
    };
  
    function validate(field, label){
        let message = '';
  
        if (!field) {
          message = `Cannot enter a blank ${label.toUpperCase()}`;
          // setStatus(`Error: ${message}`);
          swal('Failed', message, 'error');
          // alert(message);
          return false;
        }
  
        if (label === 'name') {
          if (field.length < 2) {
            message = `${label.toUpperCase()} needs to be more than one character`;
            // setStatus(`Error: ${message}`);
            swal('Failed', message, 'error');
            // alert(message);
            return false;
          } else {
            let returnMessage = nameValidateFormat(field);
            if (returnMessage) {
              // alert(returnMessage);
              swal('Failed', returnMessage, 'error');
              return false;
            } else {
              return true
            }
        }};

        if (label === 'email') {
          if (field.length < 5) {
            message = `${label.toUpperCase()} needs to be more than 5 (five) characters AND present special format`;
            setStatus(`Error: ${message}`);
            // alert(message);
            swal('Failed', message, 'error');
            return false;
          } else {
            let returnMessage = emailValidateFormat(field);
            if (returnMessage) {
              // alert(returnMessage);
              swal('Failed', returnMessage, 'error');
              return false;
            } else {
              return true
            }
          }
        }        
  
        if ( (label === 'password') && (field.length < 8) ) {
          message = `${label.toUpperCase()} needs at least 8 (eight) characters`;
          setStatus(`Error: ${message}`);
          // alert(message);
          swal('Failed', message, 'error');
          return false;
        }
        
        setStatus('');
        // setShowButtonAdd(true);
        return true;
    }
  
    function nameValidation(e) {
      // a place for specific format and advanced requirements
      log(e.key)();
      
      // maybe jump to next field?
      if (e.key === 'Enter' || e.keyCode === 13) {
        
      }
  
      return true;
    }
  
    function emailValidation(e) {
      // a place for specific format and advanced requirements
      log(e.currentTarget.value)();
  
      // maybe jump to next field?
      if (e.key === 'Enter' || e.keyCode === 13) {
        
      }
  
      // return true;
      return emailValidateFormat(e.currentTarget.value);
  
    }
  
    function passwordValidation(e) {
      // a place for specific format and advanced requirements
      log(e.currentTarget.value)();
  
      // maybe jump to next field? or hit submit ?
      if (e.key === 'Enter' || e.keyCode === 13) {
        
      }
  
      return true;
    }

    function handleAllValidationsFormik(values) {
      if (!validate(values.name,     'name'))     return false;
      if (!validate(values.email,    'email'))    return false;
      if (!validate(values.password, 'password')) return false;

      // check if user already exists
      if (containsName_and_Email(ctx.users, values.name, values.email)) {
        let error = 'User Already Exists!';
        // alert(`Error: ${error}`);
        swal('Failed', `Error: ${error}`, 'error');
        setStatus(`${error} Hint: the provided e-mail is registered`);
        return false;
      };

      return true;
    }    

    function outputMessageAccountCreationError(userExists, optionalMessage) {
      if (!optionalMessage) optionalMessage = '';
      if (!userExists) {
        let error = 'Please try again later.';
        // alert(`Create Account failed: something went wrong!\n${error}\n${optionalMessage}`);
        swal('Failed', `Create Account failed: something went wrong!\n${error}\n${optionalMessage}`, 'error');
        setStatus(error);
        return
      } else {
        let error = 'User Already Exists!';
        // alert(`Error: ${error} ${optionalMessage}`);
        swal('Failed', `Error: ${error} ${optionalMessage}`, 'error');
        setStatus(`${error} Hint: Please use login page!`);
        return false;
      }
    }

    function submitCreateAccount(name, email, password) {

      var message = '';
      var url = `${baseURL}/accounts/create/`;
      log(url)();
      // var body = `{"email":"${email}"}`;
      // const info = req.query.info;
      // const userid = req.query.id;
      // const key = 'fde7f8d0b3c9471cbf787ea0fb0ca043';
      log(`Sent login for:`)();
      log({email: email})();
      
      // https://www.npmjs.com/package/superagent
      superagent.post(url)
        .send({name: name, email: email, password: password, externalIP: ctx.externalIP}) //, userid, key})
        .end((err, res) => {
          // the answer is processed here
          if (err) {
            //global.logger.error(err);
            log(err);
            let error = 'Connection error.';
            // alert(`Create Account failed: ${error}\n${err}`);
            swal('Failed', `Create Account failed: ${error}\n${err}`, 'error');
            setStatus(error);
            return
          } else {
            log(res)();
            log(res.text)();
  
            // ATTENTION: when select uses "findOne" we receive a single document
            // ATTENTION: when select uses "find" we receive and array with the answers
            // const account = res.body.account[0];
            const account = res.body.account;
            const userExists = res.body.userExists;
            // do some async action with result:
            log(`Received reply:`)();
            log(account)();
            // test if account is the same meaning user already exists:
            if (!account) {
              // if (!userExists) {
              //   let error = 'Please try again later.';
              //   alert(`Create Account failed: something went wrong!\n${error}`);
              //   setStatus(error);
              //   return
              // } else {
              //   let error = 'User Already Exists!';
              //   alert(`Error: ${error}`);
              //   setStatus(`${error} Hint: Please use login page!`);
              //   return false;
              // }
              outputMessageAccountCreationError(userExists);
            } else {
              // A SERIES OF TESTS AND MESSAGES:
  
              if ((account.status === 'closed')||(account.status === 'blocked')) {
                let error = `Account is ${account.status}`;
                // alert(`Create Account failed: please contact our support for help!\n${error}`);
                swal('Failed', `Create Account failed: please contact our support for help!\n${error}`, 'error');
                setStatus(error);
                return
              }
  
              // a consistency test of EMAIL
              if (account.email.toLowerCase() !== email.toLowerCase()) {
                let error = `Please try again later.`;
                // alert(`Create Account failed: communication error!\n${error}`);
                swal('Failed', `Create Account failed: communication error!\n${error}`, 'error');
                setStatus(error);
                return
              }

              // a consistency test of NAME
              // for now, we'll not stop the process but we include a warning to the user
              if (account.name.toUpperCase() !== name.toUpperCase()) {
                message += 'Beaware that names did not match exactly.\nCheck if it is necessary to modify you data\n';
                // alert(`Create Account failed: communication error!\n${error}`);
              }              
            
              if (account.status === 'new') {
                  message += 'Successfully Created Account and you are now logged in';
                }
              
              if (account.status === 'active') {
                  message += 'Account already exists and you are now logged in';
                }
  
              // success!
              // alert(`${message}`);
              swal('Success', message, 'success');
              setStatus('');
              // setName(account.name);
              // setBalance(account.balance);
              // setStatement(account.statement);
              // setPassword(password);

              ctx.handleSetNewUser({_id: account._id,
                name: account.name,
                email: account.email,
                password: account.password,
                balance: account.balance,
                statement: account.statement,
                status: account.status,
                accessToken: account.accessToken,
                refreshToken: account.refreshToken,
                loggedIn: true})
          
              // if valid existing user was retrieved and returned, then all we need is to make him/her the current user
              // ctx.currentUser = account;
              // ctx.currentUser.password = password;
  
              // update all users
              // should we still use all users ?
              // first we need to check if it is not on local memory
              let found = containsEmail_and_Update(ctx.users, email, account);
              if (!found) {
                ctx.users.push({
                  _id: account._id,
                  name: account.name,
                  email: account.email,
                  password: password,
                  balance: account.balance,
                  statement: account.statement,
                  status: account.status,
                  accessToken: account.accessToken,
                  refreshToken: account.refreshToken});
                }          
  
                // setLoggedIn(true);
                // ctx.loggedIn = true;
                // setShowButtonAdd(true);
                // this alert is not necessary anymore:
                // alert(`User ${account.name} Successfully Logged in`);
                log('Try to redirect at getName')();
                history.push("/statement");
            }
          }
        })     
    }  
    
    function handleCreateGoogleFirebaseEmailPassword(values) {
      // https://firebase.google.com/docs/auth/web/password-auth?msclkid=5453f120bc0311ecae1f85dae2588a53#web-version-9
      log('handleCreate thru Formik for ',values)();
      log('handleCreate thru Formik for ',name, email, password)();
      if (!handleAllValidationsFormik(values)) return false;

      // submit to Google create user email and password first
      // const auth  = getAuth();

      // I've gotten auth and saved into "firebaseApp.auth"
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          // Signed in
          if (userCredential.user) {
            const user = userCredential.user;
            // ...
            log(user)();
            submitCreateAccount(values.name, values.email, values.password);
          } else {
            // for now, our getName also handles errors
            submitCreateAccount(values.name, values.email, values.password);
          }
        })
        .catch( (error) => {
          // log(error.message)();
          // log(error.code)();
          let message = error.code + ':' + error.message;
          log(message)();
          
          // How will Firebase auth distinguish error in creation from user already exists?
          // const userExists = res.body.userExists;

          outputMessageAccountCreationError(false, message);
      });        
    }
  
    function handleCreate(values){
      log('handleCreate thru Formik for ',values)();
      log('handleCreate thru Formik for ',name, email, password)();
      if (!handleAllValidationsFormik(values)) return false;
  
      // setBalance(0);
      // setStatement([]);
  
      log('about to submit create account thru Formik for ', values.name, values.email)(); //, balance, statement)();

      submitCreateAccount(values.name, values.email, values.password);
    }        
  
    function clearForm(){

      if (ctx.currentUser.refreshToken) {
      // google firebase LOGOUT too ?
      // ...
      
      // let's try the logout call so that the server can imediately cancel current user jwt token
      var url = `${baseURL}/accounts/logout/`;
      superagent.post(url)
        .send({refreshToken: ctx.currentUser.refreshToken})
        .end((err, res) => {
          // the answer is processed here
          if (err) {
            //global.logger.error(err);
            log(err);
            
            // no change in status is really necessary as logout will not demand successful response
            // let error = 'Connection error.';
            // alert(`Invalid login: ${error}\n${err}`);
            // setStatus(error);
            
            return
          } else {
            log(res)();
            log(res.text)();
          }

        }) 
      }

      setName('');
      setEmail('');
      setPassword('');
      // setBalance(0);
      // setStatement([]);
      // setShowButtonAdd(false);

      setValidName(false);
      setValidEmail(false);
      setValidPassword(false);

      currentUserNull();
  
      log(ctx)();
    }
  
    function CardStatus() {
      return(
        <div className="erroCreateAccount">
        { (!status) ? (
          <div></div>
          ):(
          <div><br/>{status}</div>)
        }
        </div>
      )
    }

    const formik = useFormik({
      initialValues: {
        name: '',
        //username: '',
        email: '',
        password: '',
        action: '',
      },
      onSubmit: async (values, actions) => {
        var result = false;

        log(actions)();
        // https://stackoverflow.com/questions/61833266/how-do-i-get-the-value-of-the-pressed-button-on-a-formik-form?msclkid=78679c52bc1711ec97b12d259fe55d51
        if (values.action === 'googleEmail') {
            result = handleCreateGoogleFirebaseEmailPassword(values);
        } else {
          if (values.action === 'ourEmail') {
            result = handleCreate(values);
          }
        }

        formik.setFieldValue('action', '');

        if (result) {
        //actions.setSubmitting(false);
        actions.resetForm();
        } else {
          //actions.setSubmitting(true);
        }
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .min(3, 'Must be at least 2 characters in 2 words')
          // .max(20, 'Must be less  than 20 characters')
          .required('Name is required')
          .matches(
            /^[a-zA-Z0-9]+ [a-zA-Z0-9]+(?:[a-zA-Z0-9 ])*$/,
            'Cannot contain special characters and requires at least 2 words'
          ),
        
        // username: Yup.string()
        //   .min(6, 'Must be at least 6 characters')
        //   .max(20, 'Must be less  than 20 characters')
        //   .required('Username is required')
        //   .matches(
        //     /^[a-zA-Z0-9]+$/,
        //     'Cannot contain special characters or spaces'
        //   ),
        
        email: Yup.string()
          .required('Email is required')
          .matches(
            // this seem to require something AFTER the DOT, but DOT itself is not mandatory:
            // /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            // mandatory "@" and "."
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            'Should contain @ and at least one dot in the domain side'
          ),        
        password: Yup.string()
          .min(8, 'Must be at least 8 characters')
          .required('Password is required')
          .matches(
            /^[a-zA-Z0-9.@!#$%&’*+/=?^_`{|}~-]+$/,
            'It may contain special characters or spaces'
          ),  
      }),
    });  
    
    function MyFormik() {
      return(
        <div>
          <FormikProvider value={formik}>
              <Form>
                  <TextInputLiveFeedback
                  label="Name"
                  id="name"
                  name="name"
                  placeholder="Example: John Doe"
                  // helpText="Must be at least two words with more than 2 characters each."
                  type="text"
                  // value={name}
                  // handleChange={e => setName(name + e.currentTarget.value)}
                  setterValid={setValidName}
                  />
                  <TextInputLiveFeedback
                  label="Email"
                  id="email"
                  name="email"
                  placeholder="Example: inbox@domain.ext or inbox@domain.ext.cy"
                  // helpText="Should contain @ and at least one dot in the domain side."
                  type="email"
                  // value={email}
                  // handleChange={e => setEmail(email + e.currentTarget.value)}
                  setterValid={setValidEmail}
                  />          
                  <TextInputLiveFeedback
                  label="Password"
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  // helpText="Must be at least 8 characters and may contain special characters."
                  type="password"
                  // value={password}
                  // handleChange={e => setPassword(password + e.currentTarget.value)}
                  setterValid={setValidPassword}
                  />        
                  <div>
                  <button 
                    type="submit"
                    className="btn btn-light"
                    id="formik-submit"
                    // disabled={((name.length===0)||(email.length===0)||(password.length===0))}
                    disabled={((!validName)||(!validEmail)||(!validPassword))}
                    onClick={() => formik.setFieldValue("action", "ourEmail")}
                    >Create Account
                  </button>
                  </div>
              </Form>
          </FormikProvider>          
        </div>
      )
    }
  
    return (
      <Card
        bgcolor="primary"
        header="Create Account"
        // status={status}
        
        // check this empty structure with ternary
        // body={show ? (<></>):(<></>)}
        
        body={ctx.currentUser ? ( 
        //body={showButtonAdd ? (  
                <>
                <h5>Success</h5>
                <button type="submit" className="btn btn-light" onClick={clearForm}>Add Another Account</button>
                </>
              ):(
                <>
                { MyFormik() }
                </>
              )}
        alternative = {!ctx.loggedIn ? (  
          <div>
            {/* this would became "Google():" but failed */}
            {/* <Google setStatus={setStatus} setEmail={setEmail} /> */}
            <button 
              type="submit"
              className="btn btn-light googleAccountLogin"
              onClick={handleLoginGoogleAccount}
              disabled={ false }
              >SignUp with Google Account
            </button>                            
              {/* Create Account with Google by email/password runs ok, but it would complicate because our email/passw is central to the project */}
              {/* <button 
                type="submit"
                className="btn btn-light"
                id="formik-submit-googleEmail"
                // disabled={((name.length===0)||(email.length===0)||(password.length===0))}
                disabled={((!validName)||(!validEmail)||(!validPassword))}
                onClick={() => formik.setFieldValue("action", "googleEmail")}
                >Create Account with Google</button>                           */}
              {/* <button
                type="reset"
                className="btn btn-light"
                id="formik-reset"
                // disabled={((name.length===0)||(email.length===0)||(password.length===0))}
                disabled={!((validName)&&(validEmail)&&(validPassword))}
                >Reset</button> */}
        
        
           {/* <CardStatus/> */}
          </div>
          ):(<></>)
        }

        status={status}
 
      />
    );
     
  }

export default CreateAccount;