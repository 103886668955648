import './Card.css';

const Card = (props) => {
      function classes(){
        const bg  = props.bgcolor ? ' bg-' + props.bgcolor : ' ';
        const txt = props.txtcolor ? ' text-' + props.txtcolor: ' text-white';
        return 'card mb-3 ' + bg + txt;
      }
    
      return (
        <div className='generalCard'>
          <div className={classes()} style={{maxWidth: "36rem"}}>
          <div className="card-header">{props.header}</div>
          <div className="card-body">
            {props.title && (<h5 className="card-title">{props.title}</h5>)}
            {props.text && (<p className="card-text">{props.text}</p>)}
            {props.body}
          </div>
          { props.alternative ? (<div className="card-footer or-separator">OR</div>):(<></>) }
          { props.alternative ? (<div className="card-footer">{props.alternative}</div>):(<></>)}
          { props.status && (<div className="card-footer erroStatus" id='createStatus'>{props.status}</div>) }
        </div>      
        </div>
 
      );    
};

export default Card;
