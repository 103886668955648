import { log } from '../context.js';
import { DateTime } from 'luxon';
import './AccountStatement.css';

function AccountStatement ( {aUser} ) {
  //const ctx = React.useContext(UserContext);  

  var statement = [];
  var name = '';

  // statement must exist even when ctx.currentUser doesn't exist
  if (aUser) {
    if (aUser.statement) {
      statement = aUser.statement;
      name = aUser.name;
    } else {
      statement = [];  
      name = '';
    }
  } else {
    statement = [];
    name = ''; 
  }

  function getLang() {
    if (navigator.languages !== undefined) 
      return navigator.languages[0]; 
    return navigator.language;
  }

  const updatedEntries = statement.map( (item, index) => {
    // https://stackoverflow.com/questions/35762351/correct-way-to-handle-conditional-styling-in-react
    return (
      <li className={ item.kind === 'D' ? "statementEntries-entry-negative" : "statementEntries-entry-positive" } key={index} style={ { color: item.kind === 'D' ? 'red' : 'blue' } }> 
        {/* {item.entry}: {DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_SHORT)} {item.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} {item.kind}  */}
        {/* {item.entry}: {DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_SHORT)} {item.value.toLocaleString('pt', { style: 'currency', currency: 'USD' })} {item.kind}  */}
        {/* {item.entry}: {DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_SHORT)}    {item.value.toLocaleString(getLang(), { style: 'currency', currency: 'USD' })} {item.kind}  */}
          <div className='row'>
            <div className='custom-col-1 entry-number'>{item.entry}:</div>
            <div className='custom-col-2 entry-date'>{DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_SHORT)}</div>
            <div className='custom-col-3 entry-currency'>{item.value.toLocaleString(getLang(), { style: 'currency', currency: 'USD' })}</div>
            <div className='custom-col-4 entry-kindDC'>{item.kind}</div>
          </div>   
      </li>
    );
  });

  const AllStatements = () => {
    log('All Statements rendered for ' + statement.length + ' entries')();

    return (
      <div className="bankStatement">
        <div className="bankStatement--title">{`Account Statement:${statement.length===0 ? ' empty' : ''}`}</div>
        <div className="statementEntries">
          <ul key="statementEntries" >{updatedEntries}</ul>
        </div>
      </div>
    );
  };  

  log(`AccountStatement rendered for ${statement.length} entries of user ${name}`)();
  
    return (
        <AllStatements />
      );

};

export default AccountStatement;

